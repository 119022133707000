import React, { useCallback } from 'react';
import logo from '../../images/logo.png';
import '../../css/footer.scss';

function Footer() {
    return (
        <footer>
            <img src={logo} className="App-logo" alt="logo"/>
        </footer>
    );
}

export default Footer;