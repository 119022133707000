import React, { useCallback } from 'react';
import '../../css/header.scss';
import banner from '../../images/albian_banner.png';

function Header() {
    return (
        <header className="App-header">
            <img src={banner} className="App-banner" alt="logo"/>
        </header>
    );
}

export default Header;