import React, { useCallback } from 'react';

//Import react-particles
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "./particles.json";

import Header from './views/common/header';
import Footer from './views/common/footer';
import discord_logo from './images/svg/discord/logo.svg';
import discord_banner from './images/svg/discord/banner.svg';

import './css/App.scss';

//Load articles
const { article1, article2, article3, article4, article5, article6 } = require('./article.json');

function Article(props) {
    return (
        <p className={props.name} dangerouslySetInnerHTML={{ __html: props.value }}></p>
    );
}

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>

            <Header />

            <main>
                <h1 className="App-title">Une nouvelle version arrive !</h1>

                <Article name="article-right" value={article1} />
                <Article name="article-left" value={article2} />
                <Article name="article-right" value={article3} />
                <Article name="article-left" value={article4} />
                <Article name="article-right" value={article5} />
                <Article name="article-left" value={article6} />

                <iframe src="https://discord.com/widget?id=451440887919017994&theme=dark" width="280" height="400" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>

                <h2>L'ensemble de l'équipe vous remercie de votre fidélité, et nous espérons vous revoir très bientôt sur Albian<b>Online</b> <i className="origins">Origins</i></h2>
            </main>

            <Footer />
        </div>
    );
}

export default App;
